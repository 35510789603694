var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.isBeingEdited &&
      _vm.transaction.getType() === 'Payment' &&
      _vm.newValue.categoryAmounts.length === 1
  )?_c('category-selector',{scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.entity.name)+" ")]}}],null,false,2370566859),model:{value:(_vm.newValue.categoryAmounts[0].categoryId),callback:function ($$v) {_vm.$set(_vm.newValue.categoryAmounts[0], "categoryId", $$v)},expression:"newValue.categoryAmounts[0].categoryId"}}):(_vm.transaction.getType() === 'Payment')?_c('span',{staticClass:"payment-category-column"},[(!_vm.isBeingEdited && _vm.transaction.categoryAmounts.length > 1)?_c('m-button',{staticClass:"category-prefix",class:{ opened: _vm.detailsOpened },attrs:{"icon":"chevron-right","is-small":"","type":_vm.ButtonType.HOVER_BORDER},on:{"click":function($event){$event.stopPropagation();return _vm.onToggleDetailsClicked.apply(null, arguments)}}}):_vm._e(),_c('p',{staticClass:"transaction-category-name"},[_vm._v(" "+_vm._s(_vm.transactionCategoryName(_vm.isBeingEdited ? _vm.newValue : _vm.transaction))+" ")])],1):(
    _vm.isBeingEdited &&
      _vm.transaction.getType() === 'Transfer' &&
      _vm.transactionRequiresCategory
  )?_c('category-selector',{scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.entity.name)+" ")]}}]),model:{value:(_vm.newValue.categoryId),callback:function ($$v) {_vm.$set(_vm.newValue, "categoryId", $$v)},expression:"newValue.categoryId"}}):_c('span',[_vm._v(" "+_vm._s(_vm.transactionCategoryName(_vm.transaction))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }