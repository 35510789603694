











































import Component, { mixins } from 'vue-class-component'
import CategorySelector from '@/components/CategorySelector.vue'
import MButton from '@/components/common/MButton.vue'
import { Prop } from 'vue-property-decorator'
import { Transaction, Transfer } from '@/store/transactions/types'
import CategoryMixin from '@/mixins/CategoryMixin.vue'
import AccountMixin from '@/mixins/AccountMixin.vue'
import { ButtonType } from '@/components/common/types'

@Component({
  components: {
    CategorySelector,
    MButton
  }
})
export default class CategoryColumn extends mixins(
  CategoryMixin,
  AccountMixin
) {
  @Prop() value!: Transaction
  @Prop() transaction!: Transaction
  @Prop(Boolean) detailsOpened!: boolean

  ButtonType = ButtonType

  get newValue(): Transaction {
    return this.value
  }

  get isBeingEdited(): boolean {
    return this.value && this.value.id === this.transaction.id
  }

  onToggleDetailsClicked(): void {
    this.$emit('toggleDetails', this.transaction)
  }

  get transactionRequiresCategory(): boolean {
    if (!this.newValue || this.newValue.getType() === 'Payment') {
      return true
    }

    const transfer = this.newValue as Transfer

    const sourceAccount = this.account(transfer.accountId)
    const targetAccount = this.account(transfer.targetAccountId)

    if (!sourceAccount || !targetAccount) {
      return false
    }

    return sourceAccount.offBudget !== targetAccount.offBudget
  }
}
