





















import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Transaction, RepeatingInterval } from '@/store/transactions/types'
import DatePicker from '@/components/DatePicker.vue'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import moment from 'moment'

@Component({
  components: {
    DatePicker
  }
})
export default class DateColumn extends mixins(GlobalMixin) {
  @Prop() value!: Transaction
  @Prop() transaction!: Transaction

  get newValue(): Transaction {
    return this.value
  }

  onDateChanged(newDate: string): void {
    if (this.newValue && moment(newDate).isSameOrBefore(moment())) {
      this.newValue.repeatingInterval = RepeatingInterval.NOT_REPEATING
    }
  }

  get isBeingEdited(): boolean {
    return this.value && this.value.id === this.transaction.id
  }

  get repeatingIntervalKeys(): string[] {
    return Object.keys(RepeatingInterval)
  }

  get isFutureTransaction(): boolean {
    if (!this.newValue || !this.newValue.date) {
      return false
    }
    return moment(this.newValue.date).isAfter(moment())
  }
}
