



















































import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import GroupedEntities from './GroupedEntities'
import MButton from '@/components/common/MButton.vue'
import MCheckbox from '@/components/common/MCheckbox.vue'
import MClearableButton from '@/components/common/MClearableButton.vue'
import MTextField from '@/components/common/MTextField.vue'
import CategoryMixin from '@/mixins/CategoryMixin.vue'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import _ from 'lodash'
import { ButtonType } from './common/types'

@Component({
  components: { MButton, MCheckbox, MClearableButton, MTextField }
})
export default class CategoryFilter extends mixins(CategoryMixin, GlobalMixin) {
  @Prop() value!: string[]

  searchInput: string | null = null
  newValue: string[] = _.clone(this.value) || []

  ButtonType = ButtonType

  @Watch('value')
  onValueChange(value: string[]): void {
    this.newValue = _.clone(value)
  }

  onApplyClicked(): void {
    if (!_.isEqual(this.newValue, this.value)) {
      this.$emit('input', this.newValue)
    }
    const mClearableCategoryButton = this.$refs
      .mClearableCategoryButton as MClearableButton
    mClearableCategoryButton.toggle()
  }

  onClearClicked(): void {
    this.newValue = []
    this.onApplyClicked()
  }

  onActiveChanged(isActive: boolean): void {
    if (isActive) {
      this.newValue = _.clone(this.value)
      this.searchInput = null
      const searchInput = this.$refs.searchInput as MTextField
      if (!this.isMobile) {
        searchInput.focus()
      }
    }
  }

  get triggerLabel(): string {
    if (this.value.length === 0) {
      return 'All Categories'
    }
    if (this.value.length === 1) {
      const selectedOption = this.allCategories.find(
        option => option.id && this.value.indexOf(option.id) > -1
      )
      return selectedOption ? selectedOption.name || '' : ''
    }
    return this.value.length.toString() + ' Categories'
  }

  get groupedCategories(): GroupedEntities[] {
    const result: GroupedEntities[] = []

    const searchString = (this.searchInput || '').toLocaleLowerCase()

    this.allMasterCategories.forEach(masterCategory => {
      const groupedEntities: GroupedEntities = {
        parent: masterCategory,
        children: []
      }

      this.allCategories
        .filter(category => category.masterCategoryId === masterCategory.id)
        .filter(category =>
          (category.name || '').toLocaleLowerCase().includes(searchString)
        )
        .forEach(category => groupedEntities.children.push(category))

      if (groupedEntities.children.length > 0) {
        result.push(groupedEntities)
      }
    })

    const globalCategories = this.allCategories
      .filter(category => !category.masterCategoryId)
      .filter(category =>
        (category.name || '').toLocaleLowerCase().includes(searchString)
      )

    if (globalCategories.length > 0) {
      result.push({
        parent: { id: 'GLOBAL_CATEGORIES', name: 'Global Categories' },
        children: globalCategories
      })
    }

    result.sort(this.compare)

    return result
  }

  compare(lhs: GroupedEntities, rhs: GroupedEntities): number {
    if (lhs.parent.id === 'HIDDEN_CATEGORIES') {
      return 1
    } else if (rhs.parent.id === 'HIDDEN_CATEGORIES') {
      return -1
    }

    if (lhs.parent.id === 'GLOBAL_CATEGORIES') {
      return 1
    } else if (rhs.parent.id === 'GLOBAL_CATEGORIES') {
      return -1
    }

    const lhName = lhs.parent.name || ''
    const rhName = rhs.parent.name || ''

    return lhName.localeCompare(rhName)
  }

  onDeleteFiltersClicked(): void {
    this.$emit('input', [])
  }

  get isFiltering(): boolean {
    return this.value.length > 0
  }
}
