



























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Transaction, Status } from '@/store/transactions/types'
import MButton from '@/components/common/MButton.vue'
import { ButtonType } from '@/components/common/types'

@Component({
  components: { MButton }
})
export default class StatusColumn extends Vue {
  @Prop() value!: Transaction
  @Prop() transaction!: Transaction

  ButtonType = ButtonType

  get newValue(): Transaction {
    return this.value
  }

  get isBeingEdited(): boolean {
    return this.value && this.value.id === this.transaction.id
  }

  get icon(): Function {
    return (transaction: Transaction): string => {
      switch (transaction.status) {
        case Status.SCHEDULED:
          return 'calendar'
        case Status.UNCLEARED:
          return 'check-circle-outline'
        case Status.CLEARED:
          return 'check-circle'
        case Status.RECONCILED:
          return 'lock'
        default:
          throw new Error('unknown status ' + transaction.status)
      }
    }
  }

  get isSuccess(): Function {
    return (transaction: Transaction): boolean => {
      switch (transaction.status) {
        case Status.SCHEDULED:
          return false
        case Status.UNCLEARED:
          return false
        case Status.CLEARED:
          return true
        case Status.RECONCILED:
          return true
        default:
          throw new Error('unknown status ' + transaction.status)
      }
    }
  }

  onStatusClicked(): void {
    if (this.newValue.status === Status.UNCLEARED) {
      this.newValue.status = Status.CLEARED
    } else if (this.newValue.status === Status.CLEARED) {
      this.newValue.status = Status.UNCLEARED
    }
  }

  onNonEditedStatusClicked(transaction: Transaction): void {
    this.$emit('statusClicked', transaction)
  }
}
