














































































































































































































































import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import {
  Transaction,
  Payment,
  Status,
  Transfer,
  CategoryAmount,
  RepeatingInterval
} from '@/store/transactions/types'
import BalanceLabel from '@/components/BalanceLabel.vue'
import SplitAmountInput from '@/components/desktop/SplitAmountInput.vue'
import CategorySelector from '@/components/CategorySelector.vue'
import AccountColumn from './AccountColumn.vue'
import DateColumn from './DateColumn.vue'
import PayeeColumn from './PayeeColumn.vue'
import CategoryColumn from './CategoryColumn.vue'
import DescriptionColumn from './DescriptionColumn.vue'
import AmountColumn from './AmountColumn.vue'
import StatusColumn from './StatusColumn.vue'
import MButton from '@/components/common/MButton.vue'
import MTextField from '@/components/common/MTextField.vue'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import TransactionEditingMixin from '@//mixins/TransactionEditingMixin.vue'
import PayeeMixin from '@/mixins/PayeeMixin.vue'
import _ from 'lodash'
import CategoryMixin from '@/mixins/CategoryMixin.vue'
import moment from 'moment'
import { ButtonType } from '@/components/common/types'

@Component({
  components: {
    CategorySelector,
    AccountColumn,
    DateColumn,
    PayeeColumn,
    CategoryColumn,
    DescriptionColumn,
    AmountColumn,
    StatusColumn,
    BalanceLabel,
    SplitAmountInput,
    MButton,
    MTextField
  }
})
export default class DesktopTransactionTable extends mixins(
  GlobalMixin,
  TransactionEditingMixin,
  CategoryMixin,
  PayeeMixin,
  GlobalMixin
) {
  @Prop() transactions!: Transaction[]
  @Prop() loading!: boolean
  @Prop() filterCategories!: string[]

  selectedTransactions: Transaction[] = []

  ButtonType = ButtonType

  get preOpenedRows(): string[] {
    if (this.filterCategories.length > 0) {
      return this.transactions
        .map(payment => payment.id)
        .filter(id => id !== null)
        .map(id => id as string)
    }
    return []
  }

  isCategoryFilterMismatch(
    row: Transaction,
    categoryAmountIndex: number
  ): boolean {
    if (!this.filterCategories || this.filterCategories.length === 0) {
      return false
    }

    if (row.getType() !== 'Payment') {
      return false
    }

    const payment = row as Payment
    if (payment.categoryAmounts.length < 2) {
      return false
    }

    if (!payment.categoryAmounts[categoryAmountIndex]) {
      return false
    }

    return !this.filterCategories.includes(
      payment.categoryAmounts[categoryAmountIndex]?.categoryId || ''
    )
  }

  @Watch('selectedTransactions')
  onSelectedTransactionsChanged(): void {
    this.$emit('update:selectedTransactions', this.selectedTransactions)
  }

  isTransactionSelectable(row: Transaction): boolean {
    return !this.paymentBeingEdited && !this.transferBeingEdited
  }

  get transactionBeingEdited(): Transaction | null {
    return this.paymentBeingEdited || this.transferBeingEdited || null
  }

  get allTransactions(): Transaction[] {
    if (this.paymentBeingEdited && !this.paymentOriginal) {
      return _.concat(this.paymentBeingEdited, this.transactions)
    } else if (this.transferBeingEdited && !this.transferOriginal) {
      return _.concat(this.transferBeingEdited, this.transactions)
    } else {
      return this.transactions
    }
  }

  onSortList(field: string, order: string): void {
    this.$emit('sort', { field, order })
  }

  onTransactionClicked(transaction: Transaction): void {
    if (
      transaction === this.paymentOriginal ||
      transaction === this.transferOriginal
    ) {
      return
    } else if (
      !transaction.id &&
      !this.paymentOriginal &&
      !this.transferOriginal
    ) {
      return
    }

    this.cancelSelected()

    if (transaction.getType() === 'Payment') {
      this.paymentBeingEdited = _.cloneDeep(transaction) as Payment
      this.paymentOriginal = transaction as Payment
    } else if (transaction.getType() === 'Transfer') {
      this.transferBeingEdited = _.cloneDeep(transaction) as Transfer
      this.transferOriginal = transaction as Transfer
    }
    this.table.openDetailRow(transaction)
  }

  cancelSelected(): void {
    this.selectedTransactions = []
    if (this.paymentBeingEdited) {
      this.table.closeDetailRow(this.paymentBeingEdited)
    } else if (this.transferBeingEdited) {
      this.table.closeDetailRow(this.transferBeingEdited)
    }
    this.cancelEdit()
  }

  closeAllDetails(): void {
    this.transactions.forEach(t => this.table.closeDetailRow(t))
  }

  onDetailsToggled(transaction: Transaction): void {
    this.table.toggleDetails(transaction)
  }

  get table(): any {
    return this.$refs.table
  }

  isRowOpened(rowId: string): boolean {
    const visibleDetailRows: string[] = this.table.visibleDetailRows
    return visibleDetailRows.includes(rowId)
  }

  async onSaveClicked(): Promise<void> {
    return this.saveEdit()
      .then(finished => {
        if (finished) {
          this.$emit('refreshRequested')
          this.cancelSelected()
        }
      })
      .catch(e => console.error('onSaveClicked failed', e))
  }

  onAddClicked(isTransfer: boolean = false): void {
    this.cancelEdit()

    let initialAccountId: string | undefined

    if (isTransfer) {
      initialAccountId = this.currentAccountId
        ? this.currentAccountId
        : this.allAccounts[0].id
      const firstOtherAccount = this.allAccounts.find(
        account => account.id !== initialAccountId
      )
      this.transferBeingEdited = new Transfer(
        undefined,
        moment().format('YYYY-MM-DD'),
        initialAccountId,
        firstOtherAccount ? firstOtherAccount.id : undefined,
        undefined,
        undefined,
        undefined,
        Status.CLEARED,
        RepeatingInterval.NOT_REPEATING
      )
    } else {
      initialAccountId = this.currentAccountId
      this.paymentBeingEdited = new Payment(
        undefined,
        moment().format('YYYY-MM-DD'),
        initialAccountId,
        undefined,
        undefined,
        undefined,
        Status.CLEARED,
        RepeatingInterval.NOT_REPEATING
      )
    }
    this.$nextTick(() => {
      let focusTarget
      if (this.currentAccountId) {
        focusTarget = this.$refs['payeeColumn-null'] as any
      } else {
        focusTarget = this.$refs['accountColumn-null'] as any
      }
      focusTarget.focus()
    })
  }

  addSubtransaction(): void {
    if (!this.paymentBeingEdited) {
      return
    }
    if (this.paymentBeingEdited && this.paymentBeingEdited.categoryAmounts) {
      this.paymentBeingEdited.categoryAmounts.push(new CategoryAmount())
    }
  }

  deleteSubtransaction(index: number): void {
    if (!this.paymentBeingEdited) {
      return
    }
    if (this.paymentBeingEdited && this.paymentBeingEdited.categoryAmounts) {
      this.paymentBeingEdited.categoryAmounts.splice(index, 1)
    }
  }

  get categoryAmountDetails() {
    return (payment: Payment) => {
      if (
        this.paymentBeingEdited &&
        this.paymentBeingEdited.id === payment.id
      ) {
        return this.paymentBeingEdited.categoryAmounts
      } else {
        return payment.categoryAmounts
      }
    }
  }

  rowClass(transaction: Transaction, index: number): string {
    return transaction.status === Status.SCHEDULED ? 'scheduled' : ''
  }

  @Watch('$route')
  onRouteChange(newVal: any): void {
    this.cancelEdit()
  }

  @Watch('transactionBeingEdited')
  onTransactionBeingEditedChanged(): void {
    if (this.transactionBeingEdited) {
      this.closeAllDetails()
      this.table.openDetailRow(this.transactionBeingEdited)
    }
  }

  @Watch('transactionBeingEdited.date')
  onPaymentDateChanged(newDateString: string, oldDateString: string): void {
    if (!this.transactionBeingEdited) {
      return
    }
    const today = moment().startOf('day')
    const newDate = moment(newDateString).startOf('day')
    const oldDate = moment(oldDateString).startOf('day')
    if (newDate.isAfter(today)) {
      this.transactionBeingEdited.status = Status.SCHEDULED
    } else if (oldDate.isAfter(today)) {
      this.transactionBeingEdited.status = Status.CLEARED
    }
  }

  @Watch('paymentBeingEdited.payeeId')
  onPayeeIdChange(newPayeeId: string): void {
    if (!this.paymentBeingEdited) {
      return
    }

    if (!newPayeeId) {
      return
    }

    if (
      !_.isEqual(this.paymentBeingEdited.categoryAmounts, [
        new CategoryAmount()
      ])
    ) {
      return
    }

    const payee = this.payees.payee(newPayeeId)
    if (!payee) {
      return
    }

    const lastUsedCategoryIds = payee.lastUsedCategoryIds || []

    const categoryAmounts: CategoryAmount[] =
      lastUsedCategoryIds.length > 0
        ? lastUsedCategoryIds.map(id => new CategoryAmount(id, 0, undefined))
        : [new CategoryAmount()]

    this.paymentBeingEdited.categoryAmounts = categoryAmounts
  }
}
