








































import Component, { mixins } from 'vue-class-component'
import AccountMixin from '@/mixins/AccountMixin.vue'
import CategoryMixin from '@/mixins/CategoryMixin.vue'
import PayeeMixin from '@/mixins/PayeeMixin.vue'
import { Prop } from 'vue-property-decorator'
import { Transaction } from '@/store/transactions/types'
import DesktopEntitySelector from '@/components/desktop/DesktopEntitySelector.vue'
import BalanceLabel from '@/components/BalanceLabel.vue'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import { Payee } from '../../../store/payees/types'

@Component({
  components: {
    DesktopEntitySelector,
    BalanceLabel
  }
})
export default class PayeeColumn extends mixins(
  AccountMixin,
  CategoryMixin,
  PayeeMixin,
  GlobalMixin
) {
  @Prop() value!: Transaction
  @Prop() transaction!: Transaction

  get newValue(): Transaction {
    return this.value
  }

  onPayeeSelected(): void {
    if (this.categorySelector) {
      this.categorySelector.focus()
    }
  }

  focus(): void {
    ;(this.$refs.payeeSelector as any).focus()
  }

  get isBeingEdited(): boolean {
    return this.value && this.value.id === this.transaction.id
  }

  get categorySelector(): any {
    return this.$refs.categorySelector
  }

  favoriteFunction(payee: Payee): boolean {
    return !!payee.favorite
  }
}
