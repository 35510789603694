











































































import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Transaction, Payment, Status } from '@/store/transactions/types'
import PayeeMixin from '@/mixins/PayeeMixin.vue'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import AccountMixin from '@/mixins/AccountMixin.vue'
import CategoryMixin from '@/mixins/CategoryMixin.vue'
import TransactionEditingMixin from '@/mixins/TransactionEditingMixin.vue'
import BalanceLabel from '@/components/BalanceLabel.vue'
import StatusIcon from '@/components/StatusIcon.vue'
import _ from 'lodash'

@Component({
  components: {
    BalanceLabel,
    StatusIcon
  }
})
export default class MobileTransactionTable extends mixins(
  PayeeMixin,
  CategoryMixin,
  AccountMixin,
  GlobalMixin,
  TransactionEditingMixin
) {
  @Prop() transactions!: Transaction[]

  toggleStatusIcon = Status.CLEARED

  get transactionsGroupedByDate(): any {
    const group = _.groupBy(this.transactions, function(transaction) {
      return transaction.date
    })
    return Object.freeze(
      Object.entries(group).map(entry => {
        return { date: entry[0], transactions: entry[1] }
      })
    )
  }

  navigateToTransactionDetails(transaction: Transaction): void {
    if (!transaction.id) {
      return
    }
    let routeName
    if (transaction instanceof Payment) {
      routeName = this.currentAccountId ? 'account-payment' : 'payment'
    } else {
      routeName = this.currentAccountId ? 'account-transfer' : 'transfer'
    }
    this.$router.push({
      name: routeName,
      params: {
        accountId: this.currentAccountId,
        transactionId: transaction.id || ''
      }
    })
  }

  rowClass(transaction: Transaction, index: number): string {
    return transaction.status === Status.SCHEDULED ? 'scheduled' : ''
  }

  onLeft(
    { reset }: { reset: Function },
    transaction: Transaction
  ): Promise<void> {
    return this.onToggleStatus(transaction).then(() => reset())
  }

  canToggleStatus(transaction: Transaction): boolean {
    return (
      transaction.status === Status.UNCLEARED ||
      transaction.status === Status.CLEARED
    )
  }

  onRight({ reset }: { reset: Function }, transaction: Transaction): void {
    this.$emit('deleteTransaction', transaction)
    setTimeout(() => {
      reset()
    }, 1000)
  }
}
