






































import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import MButton from '@/components/common/MButton.vue'
import MCheckbox from '@/components/common/MCheckbox.vue'
import MClearableButton from '@/components/common/MClearableButton.vue'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import _ from 'lodash'
import { Status } from '@/store/transactions/types'
import { ButtonType } from './common/types'

@Component({
  components: { MButton, MClearableButton, MCheckbox }
})
export default class StatusFilter extends mixins(GlobalMixin) {
  @Prop() value!: Status[]

  newValue: Status[] = _.clone(this.value) || []

  ButtonType = ButtonType

  @Watch('value')
  onValueChange(value: Status[]): void {
    this.newValue = _.clone(value)
  }

  onApplyClicked(): void {
    if (!_.isEqual(this.newValue, this.value)) {
      this.$emit('input', this.newValue)
    }
    const clearableStatusButton = this.$refs
      .mClearableStatusButton as MClearableButton
    clearableStatusButton.toggle()
  }

  onDeleteFiltersClicked(): void {
    this.$emit('input', [])
  }

  onClearClicked(): void {
    this.newValue = []
    this.onApplyClicked()
  }

  onNotScheduledClicked(): void {
    this.newValue = [Status.UNCLEARED, Status.CLEARED, Status.RECONCILED]
    this.onApplyClicked()
  }

  onActiveChanged(isActive: boolean): void {
    if (isActive) {
      this.newValue = this.value
    }
  }

  get triggerLabel(): string {
    if (this.value.length === 0) {
      return 'All Statuses'
    }
    if (this.value.length === 1) {
      return this.value[0].toString()
    }
    if (
      this.value.length === 3 &&
      this.value.findIndex(s => s === Status.SCHEDULED) === -1
    ) {
      return 'Not Scheduled'
    }
    return this.value.length.toString() + ' Statuses'
  }

  get isActive(): boolean {
    return this.value.length > 0
  }

  get options(): string[] {
    return Object.values(Status)
  }
}
