





















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import AmountInput from '@/components/AmountInput.vue'

@Component({
  components: { AmountInput }
})
export default class SplitAmountInput extends Vue {
  @Prop() value!: number
  @Prop() disabled!: boolean

  outflow: number | null = this.value <= 0 ? -this.value : null
  inflow: number | null = this.value > 0 ? this.value : null

  newValue: number = this.value

  mounted(): void {
    this.outflow = this.value <= 0 ? -this.value : null
    this.inflow = this.value > 0 ? this.value : null

    this.newValue = this.value
  }

  @Watch('value')
  onValueChange(value: number): void {
    this.newValue = value
    this.outflow = value <= 0 ? -value : null
    this.inflow = value > 0 ? value : null
  }

  @Watch('outflow')
  onOutflowChange(newOutflow: number | null): void {
    this.outflow = newOutflow
    if (newOutflow) {
      this.newValue = -newOutflow
      this.$emit('input', this.newValue)
    }
  }

  @Watch('inflow')
  onInflowChange(newInflow: number | null): void {
    this.inflow = newInflow
    if (newInflow) {
      this.newValue = newInflow
      this.$emit('input', this.newValue)
    }
  }
}
