

































































































































import DefaultLayout from '@/layouts/DefaultLayout.vue'
import Component, { mixins } from 'vue-class-component'
import moment from 'moment'
import 'moment/locale/de'
import DatePicker from '@/components/DatePicker.vue'
import { Transaction, Transfer, Payment } from '@/store/transactions/types'
import TransactionStorage, {
  defaultTransactionStorage
} from '@/TransactionStorage'
import { accountModule } from '@/store/accounts/module'
import { categoryModule } from '@/store/categories/module'
import { Watch } from 'vue-property-decorator'
import { Account } from '@/store/accounts/types'
import BalanceLabel from '@/components/BalanceLabel.vue'
import MButton from '@/components/common/MButton.vue'
import AccountMixin from '@/mixins/AccountMixin.vue'
import CategoryMixin from '@/mixins/CategoryMixin.vue'
import PayeeMixin from '@/mixins/PayeeMixin.vue'
import CategoryFilter from '@/components/CategoryFilter.vue'
import DateFilter from '@/components/DateFilter.vue'
import { DateRange } from '@/types'
import MobileTransactionTable from './mobile/MobileTransactionTable.vue'
import DesktopTransactionTable from './desktop/DesktopTransactionTable.vue'
import { SortSettings } from './types'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import StatusFilter from '@/components/StatusFilter.vue'
import { EventBus } from '@/EventBus'
import { ButtonType } from '@/components/common/types'

@Component({
  components: {
    BalanceLabel,
    CategoryFilter,
    DateFilter,
    DatePicker,
    DefaultLayout,
    DesktopTransactionTable,
    MButton,
    MobileTransactionTable,
    StatusFilter
  }
})
export default class TransactionList extends mixins(
  GlobalMixin,
  AccountMixin,
  CategoryMixin,
  PayeeMixin
) {
  transactions: Transaction[] = []
  transactionsCount: number = 0
  selectedTransactions: Transaction[] = []
  loading = false
  transactionStorage: TransactionStorage = defaultTransactionStorage

  accounts = accountModule
  categories = categoryModule

  numberOfPaginationItems: number = 4
  sortField: string = 'date'
  sortDirection: string = 'desc'

  reconciliationDialogActive = false

  ButtonType = ButtonType

  initialize(): void {
    this.transactions = []
    this.selectedTransactions = []
    this.fetchTransactions()
  }

  get pageSize(): number {
    return this.isMobile ? 750 : 200
  }

  get filterCategories(): string[] {
    const query = this.$route.query
    if (!query) {
      return []
    }

    const categoryIds = query.filterCategories as string
    if (categoryIds) {
      return categoryIds.split(',')
    }

    return []
  }

  get dateRange(): DateRange {
    const query = this.$route.query
    const result = new DateRange()
    if (!query) {
      return result
    }

    const fromString = query.from as string
    if (fromString) {
      result.from = moment(fromString).toDate()
    }

    const untilString = query.until as string
    if (untilString) {
      result.until = moment(untilString).toDate()
    }

    return result
  }

  get filterStatuses(): string[] {
    const query = this.$route.query
    if (!query) {
      return []
    }

    const statuses = query.filterStatuses as string
    if (statuses) {
      return statuses.split(',')
    }

    return []
  }

  get currentPage(): number {
    const query = this.$route.query
    if (!query) {
      return 1
    }

    const currentPage = query.currentPage as any
    if (!currentPage) {
      return 1
    }

    return Number.parseInt(currentPage, 10)
  }

  onAccountReconciled(accountId: string): void {
    if (this.currentAccountId === accountId) {
      this.initialize()
    }
  }

  beforeDestroy(): void {
    EventBus.$off('accountReconciled', this.onAccountReconciled)
  }

  mounted(): void {
    this.initialize()
    const locale = window.navigator.language
    if (locale) {
      moment().locale(locale)
    }
    EventBus.$on('accountReconciled', this.onAccountReconciled)
  }

  @Watch('$route')
  onRouteChange(newVal: any): void {
    this.selectedTransactions = []
    this.fetchTransactions()
  }

  get deleteButtonLabel(): string {
    const selectionCount = this.selectedTransactions.length
    return `Delete ${selectionCount} Transaction${
      selectionCount !== 1 ? 's' : ''
    }`
  }

  get currentAccount(): Account | undefined {
    return this.accounts.account(this.currentAccountId)
  }

  get subtitle(): string {
    let result = 'All Accounts'
    if (this.currentAccount) {
      result = this.currentAccount.name || ''
      if (this.currentAccount.description) {
        result += ` - ${this.currentAccount.description}`
      }
    }
    return result
  }

  async fetchTransactions(): Promise<void | never[]> {
    this.loading = true
    let countResponse, transactionsResponse
    return Promise.all([
      this.transactionStorage.countTransactions(
        this.currentAccountId,
        this.filterCategories,
        this.filterStatuses,
        this.dateRange
      ),
      this.transactionStorage.findAll(
        this.currentPage - 1,
        this.pageSize,
        this.sortField,
        this.sortDirection,
        this.currentAccountId,
        this.filterCategories,
        this.filterStatuses,
        this.dateRange
      )
    ])
      .then(
        res => {
          ;[countResponse, transactionsResponse] = res
          this.transactionsCount = countResponse.data
          const result: Transaction[] = []
          if (transactionsResponse && transactionsResponse.data) {
            transactionsResponse.data.forEach((t: any) => {
              if (t.targetAccountId) {
                const transfer = new Transfer(
                  t.id,
                  t.date,
                  t.accountId,
                  t.targetAccountId,
                  t.categoryId,
                  t.amount,
                  t.description,
                  t.status,
                  t.repeatingInterval
                )
                result.push(transfer)
              } else {
                const payment = new Payment(
                  t.id,
                  t.date,
                  t.accountId,
                  t.payeeId,
                  t.categoryAmounts,
                  t.description,
                  t.status,
                  t.repeatingInterval
                )
                result.push(payment)
              }
            })
          }
          this.transactions = result
        },
        error => {
          console.error(error)
          this.transactions = []
        }
      )
      .catch(e => console.error('fetchTransactions failed', e))
      .finally(() => {
        this.loading = false
      })
  }

  onSortList(sortSettings: SortSettings): void {
    this.sortField = sortSettings.field
    this.sortDirection = sortSettings.order
    this.fetchTransactions()
  }

  onAddClicked(isTransfer: boolean = false): void {
    if (this.isMobile) {
      this.navigateToTransactionCreationDetails(isTransfer)
    } else {
      const desktopTransactionTable = this.$refs
        .desktopTransactionTable as DesktopTransactionTable
      desktopTransactionTable.onAddClicked(isTransfer)
    }
  }

  navigateToTransactionCreationDetails(isTransfer: boolean): void {
    if (isTransfer) {
      const routeName = this.currentAccountId ? 'account-transfer' : 'transfer'
      this.$router.push({
        name: routeName,
        params: {
          accountId: this.currentAccountId,
          transactionId: 'new-transfer'
        }
      })
    } else {
      const routeName = this.currentAccountId ? 'account-payment' : 'payment'
      this.$router.push({
        name: routeName,
        params: {
          accountId: this.currentAccountId,
          transactionId: 'new-payment'
        }
      })
    }
  }

  onDeleteClicked(): void {
    if (this.selectedTransactions && this.selectedTransactions.length > 0) {
      this.deleteTransactions(this.selectedTransactions)
    }
  }

  onDeleteTransaction(transaction: Transaction): void {
    this.deleteTransactions([transaction])
  }

  deleteTransactions(transactions: Transaction[]): void {
    this.transactionStorage
      .delete(transactions)
      .then(() => {
        this.fetchTransactions()
        this.selectedTransactions = []
      })
      .catch(e => console.error('delete failed', e))
  }

  get currentAccountBalance(): number {
    return this.accounts.accountBalance(this.currentAccountId)
  }

  get clearedAccountBalance(): number {
    return this.accounts.clearedAccountBalance(this.currentAccountId)
  }

  onCurrentPageChanged(newPage: number): void {
    this.updateUrl(
      this.dateRange,
      this.filterCategories,
      this.filterStatuses,
      newPage
    )
  }

  onDateFilterChanged(newDateRange: DateRange): void {
    this.updateUrl(
      newDateRange,
      this.filterCategories,
      this.filterStatuses,
      this.currentPage
    )
  }

  onFilterCategoriesChanged(newCategories: string[]): void {
    this.updateUrl(
      this.dateRange,
      newCategories,
      this.filterStatuses,
      this.currentPage
    )
  }

  onFilterStatusesChanged(newStatuses: string[]): void {
    this.updateUrl(
      this.dateRange,
      this.filterCategories,
      newStatuses,
      this.currentPage
    )
  }

  updateUrl(
    newDateRange: DateRange,
    newFilterCategories: string[],
    newStatusesFilter: string[],
    currentPage: number = 1
  ): void {
    const from = newDateRange.from
      ? moment(newDateRange.from).format('YYYY-MM-DD')
      : undefined
    const until = newDateRange.until
      ? moment(newDateRange.until).format('YYYY-MM-DD')
      : undefined

    const categories =
      newFilterCategories.length > 0 ? newFilterCategories.join(',') : undefined
    const statuses =
      newStatusesFilter.length > 0 ? newStatusesFilter.join(',') : undefined

    this.$router.push({
      query: Object.assign({}, this.$route.query, {
        from: from,
        until: until,
        filterCategories: categories,
        filterStatuses: statuses,
        currentPage: currentPage
      })
    })
  }

  get titleIcon(): string {
    return this.currentAccountId ? 'piggy-bank' : 'bank'
  }

  get titleIconColorClass(): string {
    return this.currentAccountId ? 'color-light-gray' : 'color-yellow'
  }

  onReconcileAccountClicked(): void {
    EventBus.$emit(
      'reconcileAccountClicked',
      this.currentAccount,
      this.clearedAccountBalance
    )
  }

  onEditAccountClicked(): void {
    EventBus.$emit('editAccountClicked', this.currentAccount)
  }
}
