


























import Component, { mixins } from 'vue-class-component'
import AccountMixin from '@/mixins/AccountMixin.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { Transaction } from '@/store/transactions/types'
import BalanceLabel from '@/components/BalanceLabel.vue'
import SplitAmountInput from '@/components/desktop/SplitAmountInput.vue'

@Component({
  components: {
    BalanceLabel,
    SplitAmountInput
  }
})
export default class AmountColumn extends mixins(AccountMixin) {
  @Prop() value!: Transaction
  @Prop() transaction!: Transaction

  newValue: Transaction = this.value

  @Watch('value')
  onValueChange(): void {
    this.newValue = this.value
  }

  get isAmountBeingEdited(): boolean {
    return this.value && this.value.id === this.transaction.id
  }

  get isMobile(): boolean {
    return (this as any).$mq === 'mobile'
  }

  get currentAccountId(): string {
    return this.$route.params.accountId
  }
}
