










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Transaction } from '@/store/transactions/types'
import MTextField from '@/components/common/MTextField.vue'

@Component({
  components: { MTextField }
})
export default class DescriptionColumn extends Vue {
  @Prop() value!: Transaction
  @Prop() transaction!: Transaction

  get newValue(): Transaction {
    return this.value
  }

  get isBeingEdited(): boolean {
    return this.value && this.value.id === this.transaction.id
  }
}
