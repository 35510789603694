























import Component, { mixins } from 'vue-class-component'
import DesktopEntitySelector from '@/components/desktop/DesktopEntitySelector.vue'
import BalanceLabel from '@/components/BalanceLabel.vue'
import AccountMixin from '@/mixins/AccountMixin.vue'
import { Prop } from 'vue-property-decorator'
import { Transaction } from '@/store/transactions/types'
import { Account } from '@/store/accounts/types'

@Component({
  components: {
    DesktopEntitySelector,
    BalanceLabel
  }
})
export default class AccountColumn extends mixins(AccountMixin) {
  @Prop() value!: Transaction
  @Prop() transaction!: Transaction

  get newValue(): Transaction {
    return this.value
  }

  focus(): void {
    ;(this.$refs.accountSelector as any).focus()
  }

  groupByAccountType(account: Account): string {
    return account.offBudget ? 'Off-Budget' : 'On-Budget'
  }

  get isAccountBeingEdited(): boolean {
    return this.value?.id === this.transaction.id
  }
}
