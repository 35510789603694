








import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Status } from '@/store/transactions/types'

@Component
export default class StatusIcon extends Vue {
  @Prop() status!: Status

  get icon(): string {
    switch (this.status) {
      case Status.SCHEDULED:
        return 'calendar'
      case Status.UNCLEARED:
        return 'check-circle-outline'
      case Status.CLEARED:
        return 'check-circle'
      case Status.RECONCILED:
        return 'lock'
      default:
        throw new Error('unknown status ' + this.status)
    }
  }

  get isSuccess(): boolean {
    switch (this.status) {
      case Status.SCHEDULED:
        return false
      case Status.UNCLEARED:
        return false
      case Status.CLEARED:
        return true
      case Status.RECONCILED:
        return true
      default:
        throw new Error('unknown status ' + this.status)
    }
  }
}
